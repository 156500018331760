<template>
	<div :class="'community-page-communities' + (isActive ? ' active' : '')">
		<span>
			<b>Remember when communities looked after each other?</b> Remember how all the moms on the block 
			would watch out for not only their children playing outdoors, but all the neighbourhood children? 
			Remember how members of a church, synagogue or temple would come to the aid of a member going 
			through tough times? Yes, this still happens, but perhaps not always to the same degree. 
			It is not that people don't care — they do, but sometimes it is awkward to approach someone with 
			an offer to help as it is to reach out and ask for help.
		</span>

		<div class="nb-vspacer" />

		<span>
			<b>We created Neobanx Communities as a social network mechanism</b> that helps communities and individuals 
			who share connections and common interests with others to lend and borrow within a community of 
			like-minded individuals, whether they reside in the same neighbourhood or across the country — all 
			in an effective, easy and safe way.
		</span>

		<div class="nb-vspacer" />

		<span>
			<b>Take the organization you belong to now and create it online.</b> Bring your friends and acquaintances 
			together and harness the power of your group, be it a service club, school, religious organization, 
			Chamber of Commerce — you name it, and make a difference to the lives of your members.
		</span>

		<div class="nb-vspacer xlarge" />

		<a class="button" @click="$emit('goto', 'StartNow')">
			Create a community now
		</a>
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'CommunityPage_Communities',

	props: {
		isActive: false
	},

	setup() {
		// TODO
	}
})
</script>

<style lang="scss" scoped>
.community-page-communities {
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 17px;
	color: rgba(#000, 0.7);
	opacity: 0;
	transition: opacity 1s;
	height: 0;
	overflow: hidden;

	.button {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 36px;
		padding: 0 16px;
		width: fit-content;
		border-radius: 4px;
		font-weight: bolder;
		font-size: 14px;
		color: rgba(#fff, 0.9);
		text-transform: uppercase;
		cursor: pointer;
		background: linear-gradient(135deg, #EA7916 0, #D16C13 100%);
	}

	.button:hover {
		filter: brightness(110%);
	}
}

.community-page-communities.active {
	opacity: 1;
	height: unset;
	overflow: unset;
}
</style>
