












































































































































import { defineComponent, reactive, watch } from '@vue/composition-api'
import CommunityPage_Communities from '@/views/CommunityPageSections/CommunityPage_Communities.vue'
import CommunityPage_StartNow from '@/views/CommunityPageSections/CommunityPage_StartNow.vue'
import CommunityPage_Forums from '@/views/CommunityPageSections/CommunityPage_Forums.vue'

export default defineComponent({
	name: 'CommunityPage',

	components: {
		CommunityPage_Communities,
		CommunityPage_StartNow,
		CommunityPage_Forums
	},

	setup() {
		const state = reactive({
			activeSection: 'Communities'
		});

		watch(
			() => state.activeSection,
			() => {
				document.getElementById('community-content')!.scrollTop = 0
			}
		)

		return { state }
	}
})
