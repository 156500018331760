<template>
	<div :class="'community-page-forums' + (isActive ? ' active' : '')">
		<div style="display: flex; align-items: center;">
			<div style="display: flex; height: 40px; width: 40px; justify-content: center; align-items: center; background-color: #333; border-radius: 50%;">
				<q-icon name="handyman" style="font-size: 25px; color: #ddd;" /> 
			</div>
			
			<div style="display: flex; margin-left: 8px;">
				This sections is not yet available.
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'CommunityPage_Forums',

	props: {
		isActive: false
	},

	setup() {
		// TODO
	}
})
</script>

<style lang="scss" scoped>
.community-page-forums {
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 17px;
	color: rgba(#000, 0.7);
	opacity: 0;
	transition: opacity 1s;
	height: 0;
	overflow: hidden;
}

.community-page-forums.active {
	opacity: 1;
	height: unset;
	overflow: unset;
}
</style>
